import styled from '@emotion/styled'

export const StarInput = styled.input`
	display: none;
	postion: absolute;
	margin-left: -9999;
`
export const StarList = styled.div`
	list-style: none;
	margin: 0;
	position: relative;
`
export const StarListItem = styled.div`
	display: inline;
`
export const Star = styled.div`
	${({ starPadding }) => starPadding && `padding-left: ${starPadding};`}
	float: right;
	cursor: ${({ isEditing }) => (isEditing ? `pointer` : 'default')};
	color: ${({ isAwarded, starColor, emptyStarColor }) => (isAwarded ? starColor : emptyStarColor)};
`
