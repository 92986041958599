import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/emotion'
import StarRating from 'components/StarRating'
import { Star } from 'components/Iconography'
import { COLORS, FONT_SIZES } from 'utils/styleHelpers'

import { Summary, SummaryRatings } from './styles'

const ReviewsSummary = ({ averageRating, totalReviews, dispensaryId, justifyContent = 'center' }) => (
	<>
		{totalReviews > 0 && (
			<Flex alignItems="center" flexDirection="row" justifyContent={justifyContent}>
				<Box>
					<StarRating
						emptyStarColor={COLORS.MEDIUM_LIGHT_GRAY}
						name="reviewRating"
						renderStarIcon={Star({ fontSize: FONT_SIZES.H5 })}
						starColor={COLORS.YELLOW}
						starCount={5}
						starRatingId={dispensaryId}
						value={averageRating}
					/>
				</Box>
				<Summary>
					{averageRating.toFixed(1)} Stars out of {totalReviews} {`review${totalReviews > 1 ? `s` : ``}`}
				</Summary>
			</Flex>
		)}
		{totalReviews === 0 && <SummaryRatings>Be the first to add a review!</SummaryRatings>}
	</>
)

ReviewsSummary.propTypes = {
	averageRating: PropTypes.number,
	dispensaryId: PropTypes.string,
	justifyContent: PropTypes.string,
	totalReviews: PropTypes.number
}

export default ReviewsSummary
