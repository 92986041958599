import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Flex } from '@rebass/emotion'
import Avatar from 'components/Avatar'
import { PROFILE_IMAGE_SIZE, DEFAULT_AVATAR_SIZE } from 'utils/constants'
import { SPACERS, DEFAULT_AVATAR_THEMES } from 'utils/styleHelpers'

const DefaultAvatarWrapper = styled(Flex)`
	margin: 0;
	margin-top: ${SPACERS.M};
	margin-bottom: ${SPACERS.M};
`
const DefaultAvatarCircle = styled.div`
	background-color: ${({ themeIndex }) => DEFAULT_AVATAR_THEMES[themeIndex].background};
	border: 1px solid ${({ themeIndex }) => DEFAULT_AVATAR_THEMES[themeIndex].border};
	border-radius: 50%;
	height: ${({ height }) => height};
	text-align: center;
	width: ${({ width }) => width};
`

const DefaultAvatarInitials = styled.div`
	font-size: calc(${({ height }) => height} / 2); /* 50% of parent */
	color: ${({ themeIndex }) => DEFAULT_AVATAR_THEMES[themeIndex].foreground};
	top: calc(${({ height }) => height} / 5); /* 25% of parent */
	line-height: 1;
	position: relative;
`

// See https://codepen.io/AllThingsSmitty/pen/dWmmQp
function ProfileAvatar({
	alt = 'Reviewed for Weedrater',
	defaultAvatarThemeIndex = 3,
	displayText,
	height = DEFAULT_AVATAR_SIZE,
	imageFileURL,
	profileImageSize = PROFILE_IMAGE_SIZE,
	width = DEFAULT_AVATAR_SIZE,
}) {
	const [imageURL, setImageURL] = useState(null)
	const [caption, setCaption] = useState('')
	const [imageHash] = useState(Date.now())

	useEffect(() => {
		setImageURL(imageFileURL)
		setCaption(displayText)
	}, [])

	return (
		<>
			{imageURL ? (
				<DefaultAvatarWrapper alignItems="center" justifyContent="center">
					<Avatar size={[profileImageSize, profileImageSize]} src={`${imageURL}?${imageHash}`} alt={alt} />
				</DefaultAvatarWrapper>
			) : (
				<DefaultAvatarWrapper alignItems="center" justifyContent="center">
					<DefaultAvatarCircle height={height} themeIndex={defaultAvatarThemeIndex} width={width}>
						<DefaultAvatarInitials height={height} themeIndex={defaultAvatarThemeIndex} width={width}>
							{caption}
						</DefaultAvatarInitials>
					</DefaultAvatarCircle>
				</DefaultAvatarWrapper>
			)}
		</>
	)
}

ProfileAvatar.propTypes = {
	alt: PropTypes.string,
	defaultAvatarThemeIndex: PropTypes.number,
	displayText: PropTypes.string.isRequired,
	height: PropTypes.string,
	imageFileURL: PropTypes.string,
	profileImageSize: PropTypes.number,
	width: PropTypes.string,
}
export default ProfileAvatar
