import React from 'react'
import PropTypes from 'prop-types'
import { formatPhone } from 'utils/stringUtils'
import Helmet from 'react-helmet'
import moment from 'moment'

const DispensarySchema = ({ dispensary }) => {
	const {
		name,
		about,
		address1,
		averageRating,
		city,
		state,
		totalReviews,
		zip,
		geoLocation: { _latitude, _longitude },
		imageUrl,
		phone,
		reviews,
	} = dispensary
	const schema = {
		'@context': 'http://www.schema.org',
		'@type': 'LocalBusiness',
		name: name,
		description: about,
		telephone: formatPhone(phone, 'NNN-NNN-NNNN'),
		address: {
			'@type': 'PostalAddress',
			streetAddress: address1,
			addressLocality: city,
			addressRegion: state,
			postalCode: zip,
			addressCountry: 'United States',
		},
		geo: {
			'@type': 'GeoCoordinates',
			latitude: _latitude.toString(),
			longitude: _longitude.toString(),
		},
		contactPoint: [
			{
				'@type': 'ContactPoint',
				telephone: phone ? `+1${formatPhone(phone, '(NNN)-NNN-NNNN')}` : '',
				contactType: 'sales',
			},
		],
		image: imageUrl,
	}
	const reviewSchema =
		reviews.length === 0
			? {}
			: {
					aggregateRating: {
						'@type': 'AggregateRating',
						ratingValue: averageRating && averageRating.toString(),
						ratingCount: totalReviews.toString(),
					},
					review: reviews.map(r => {
						const { dateCreated, rating, reviewerName, reviewText } = r
						return {
							'@type': 'Review',
							author: reviewerName,
							datePublished: moment(dateCreated).format('YYYY-MM-DD'),
							reviewBody: reviewText,
							reviewRating: {
								'@type': 'Rating',
								bestRating: '5',
								ratingValue: rating.toString(),
								worstRating: '1',
							},
						}
					}),
			  }
	return (
		<Helmet>
			<script type="application/ld+json">{JSON.stringify({ ...schema, ...reviewSchema })}</script>
		</Helmet>
	)
}

DispensarySchema.propTypes = {
	dispensary: PropTypes.object,
	reviews: PropTypes.array,
	menu: PropTypes.object,
}
DispensarySchema.defaultProps = {
	reviews: [],
}

export default DispensarySchema
