import styled from '@emotion/styled'
import { FONT_SIZES, COLORS, SPACERS } from 'utils/styleHelpers'

export const Summary = styled.div`
	padding-left: ${SPACERS.M};
	color: ${COLORS.YELLOW};
	font-size: ${FONT_SIZES.H6};
	font-weight: 500;
`
export const SummaryRatings = styled.div`
	color: ${COLORS.YELLOW};
	font-size: ${FONT_SIZES.H6};
	font-weight: 500;
`
