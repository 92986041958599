import { COLORS, FONT_SIZES } from 'utils/styleHelpers'

const fullTheme = {
	ReviewsContainer: {
		borderRadius: `0 10px 0 10px;`,
		borderTop: `1px solid ${COLORS.MEDIUM_LIGHT_GRAY};`,
		borderBottom: `1px solid ${COLORS.MEDIUM_LIGHT_GRAY};`,
		borderLeft: `1px solid ${COLORS.MEDIUM_LIGHT_GRAY};`,
		borderRight: `1px solid ${COLORS.MEDIUM_LIGHT_GRAY};`,
	},
	ReviewsElementStars: {
		border: 'none;',
		'flex-basis': '40%;',
		MQL: {
			'flex-basis': '20%;',
		},
	},
	ReviewsElementTitle: {
		'font-size': `${FONT_SIZES.H6}`,
		border: 'none;',
		'flex-basis': '60%;',
		MQL: {
			'flex-basis': '80%;',
		},
	},
	ReviewsProfileAvatar: {
		'flex-basis': '30%;',
	},
	ReviewTitle: {
		'font-size': `${FONT_SIZES.H6};`,
		'font-style': 'normal;',
		MQL: {
			'font-size': `${FONT_SIZES.H4};`,
		},
	},
}

export default fullTheme
