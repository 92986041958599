import React from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import ShowMore from 'react-show-more'
import { Flex, Box } from '@rebass/emotion'
import StarRating from 'components/StarRating'
import { COLORS, MQ, FONT_SIZES, SPACERS } from 'utils/styleHelpers'
import { Star } from 'components/Iconography'
import ProfileAvatar from 'components/ProfileAvatar'
import { getInitials } from 'utils/userHelpers'
import { DEFAULT_AVATAR_THUMB_SIZE, PROFILE_IMAGE_THUMB_SIZE, FIREBASE } from 'utils/constants'
import { getAllReviews } from 'wrFirebase/reviewActions'

import moment from 'moment'
import styled from '@emotion/styled'

const ReviewsElement = styled.div`
	background: ${COLORS.WHITE};
	font-size: ${FONT_SIZES.H5};
	padding: ${SPACERS.M};
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`

const ReviewerName = styled.span`
	color: ${COLORS.GREEN};
	font-size: ${FONT_SIZES.H5};
	font-weight: 700;
`

const ReviewCreatedDate = styled.div`
	padding-left: ${SPACERS.M};
	font-size: ${FONT_SIZES.H6};
`
const ToggleButton = styled.button`
	background: none !important;
	color: ${COLORS.GREEN};
	border: none;
	padding: 0 !important;
	margin-left: 3px;
	font: inherit;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`

const NoReviews = styled.div`
	background: ${COLORS.PALE_GREEN};
	font-weight: 500;
	margin-bottom: ${SPACERS.L};
	padding: ${SPACERS.L};
	text-align: center;
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`

const Emoji = styled.span`
	font-size: 30px;
	line-height: 30px;
`

class ReviewList extends React.PureComponent {
	static propTypes = {
		isReviewTextHidden: PropTypes.bool,
		isShowingAllReviews: PropTypes.bool,
		isShowingNodeName: PropTypes.bool,
		numPreview: PropTypes.number,
		reviewNodeId: PropTypes.string,
		reviewNodeType: PropTypes.string,
		reviews: PropTypes.array,
	}

	static defaultProps = {
		isReviewTextHidden: false,
		numPreview: 2,
		isShowingNodeName: false,
		isShowingAllReviews: false,
		reviewNodeType: null,
		reviewNodeId: null,
	}

	state = {
		// eslint-disable-next-line react/destructuring-assignment
		limit: this.props.numPreview,
		showMore: true,
		allReviews: [],
	}

	async componentDidMount() {
		const { isShowingAllReviews, reviewNodeId, reviewNodeType } = this.props
		if (isShowingAllReviews && reviewNodeId && reviewNodeType) {
			const allReviews = await getAllReviews(reviewNodeType, reviewNodeId)
			this.setState({ allReviews })
		}
	}

	showMore = () => {
		const {
			reviews: { length },
		} = this.props
		this.setState({ showMore: false, limit: length })
	}

	showLess = () => {
		const { numPreview } = this.props
		this.setState({ showMore: true, limit: numPreview })
	}

	render() {
		const { numPreview, reviews } = this.props
		const { showMore, limit, allReviews } = this.state

		const hasReviews = reviews.length && reviews[0].userID !== '999'
		const hasMoreReviews = reviews.length > numPreview
		const reviewPreview = allReviews.length > 0 ? allReviews : reviews.slice(0, limit)

		// const fontSize = FONT_SIZES.H4
		const reviewComponents = reviewPreview.map((review, index) => {
			const {
				dateCreated,
				defaultAvatarThemeIndex,
				reviewText,
				reviewType,
				reviewerName,
				rating,
				id,
				userID,
			} = review

			return (
				<div
					css={css`
						background: ${COLORS.WHITE};
						transition: background 0.5s;
						.dark & {
							background: ${COLORS.MEDIUM_GRAY};
						}
					`}
					key={id}
				>
					{reviewType !== 'nothing' && (
						<Flex
							flexDirection="column"
							justifyContent="flex-start"
							css={css`
								${MQ.L} {
									padding: ${SPACERS.M};
								}
							`}
						>
							<Box
								css={css`
									padding-left: ${SPACERS.M};
									padding-right: ${SPACERS.M};
								`}
							>
								<Flex flexDirection={['column', 'row']}>
									<Box
										css={css`
											width: 100%;
										`}
									>
										<Flex flexDirection="row" alignItems={['center']}>
											<Box
												css={css`
													padding-top: ${SPACERS.S};
													padding-right: ${SPACERS.S};
													padding-left: ${SPACERS.S};
												`}
											>
												<ProfileAvatar
													defaultAvatarThemeIndex={defaultAvatarThemeIndex}
													displayText={getInitials(review)}
													height={DEFAULT_AVATAR_THUMB_SIZE}
													imageFileURL={`${FIREBASE.STORAGE.BASE_URL}/profile-images/${userID}.png`}
													profileImageSize={PROFILE_IMAGE_THUMB_SIZE}
													width={DEFAULT_AVATAR_THUMB_SIZE}
													alt={`Reviewed By: ${reviewerName}`}
												/>
											</Box>
											<Box
												css={css`
													padding: ${SPACERS.S};
												`}
											>
												<ReviewerName>{reviewerName}</ReviewerName>
											</Box>
										</Flex>
									</Box>
									<Box
										alignSelf={['flex-start', 'center']}
										css={css`
											width: 100%;
										`}
									>
										<Flex flexDirection="row" justifyContent={['flex-start', 'flex-end']}>
											<Box>
												<StarRating
													emptyStarColor={COLORS.MEDIUM_LIGHT_GRAY}
													isEditing={false}
													name="reviewRating"
													renderStarIcon={Star({ fontSize: FONT_SIZES.H5 })}
													starRatingId={id}
													starColor={COLORS.YELLOW}
													starCount={5}
													value={rating}
												/>
											</Box>
											<Box alignSelf="center">
												<ReviewCreatedDate>
													{rating.toFixed(1)} Stars on{' '}
													{moment(dateCreated).format('MMMM Do YYYY')}
												</ReviewCreatedDate>
											</Box>
										</Flex>
									</Box>
								</Flex>
							</Box>

							<Box
								css={css`
									font-size: ${FONT_SIZES.H5};
									padding: ${SPACERS.M};
									${index + 1 !== reviewPreview.length &&
										`border-bottom: 1px solid ${COLORS.MEDIUM_LIGHT_GRAY}`};
								`}
							>
								<ShowMore less="less" lines={3} more="more">
									{reviewText}
								</ShowMore>
							</Box>
						</Flex>
					)}
				</div>
			)
		})
		const showMoreButton = (
			<ReviewsElement>
				<ToggleButton onClick={this.showMore}>&#x25BC; More Reviews</ToggleButton>
			</ReviewsElement>
		)
		const showLessButton = (
			<ReviewsElement>
				<ToggleButton onClick={this.showLess}>&#x25B2; Less Reviews</ToggleButton>
			</ReviewsElement>
		)

		if (!hasReviews) {
			/* eslint-disable jsx-a11y/accessible-emoji */
			return (
				<NoReviews>
					<Emoji ariaLabel="grinning emoji" role="img">
						😬
					</Emoji>{' '}
					Sorry, no reviews yet
					<br />
					<Emoji ariaLabel="praise emoji" role="img">
						🙌
					</Emoji>{' '}
					Be the first to post one!
				</NoReviews>
			)
			/* eslint-enable jsx-a11y/accessible-emoji */
		}

		return (
			<div>
				{reviewComponents}
				{hasReviews && hasMoreReviews && showMore && showMoreButton}
				{hasReviews && !showMore && showLessButton}
			</div>
		)
	}
}

export default ReviewList
