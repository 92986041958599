import styled from '@emotion/styled'
import { STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP } from 'utils/constants'
import { MQ } from 'utils/styleHelpers'

const Avatar = styled.img`
	vertical-align: middle;
	width: ${({ size = [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP] }) => size[0]}px;
	height: ${({ size = [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP] }) => size[0]}px;
	margin-bottom: 0;
	border-radius: 50%;
	${MQ.L} {
		width: ${({ size = [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP] }) => size[1]}px;
		height: ${({ size = [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP] }) => size[1]}px;
	}
	${MQ.M} {
		width: ${({ size = [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP] }) => size[1]}px;
		height: ${({ size = [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP] }) => size[1]}px;
	}
`
export default Avatar
