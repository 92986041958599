import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import GlobalLayout from 'components/GlobalLayout'
import { NODE_TYPES } from 'utils/constants'
import { graphql } from 'gatsby'
import { stampTime, eventDispatch } from 'utils/tracker'
import DispensarySchema from 'components/DispensarySchema'
import ReviewsSummary from 'components/ReviewsSummary'
import ReviewsList from 'components/ReviewsList'
import fullTheme from 'components/ReviewsList/ReviewFullTheme'
import { ReviewsListWrapper } from 'components/FormStyles'
import { FONT_SIZES } from 'utils/styleHelpers'

const ReviewPageHeader = styled.h1`
	text-transform: uppercase;
	font-size: ${FONT_SIZES.H4};
	border: none;
	margin: 0;
	padding: 0;
`

function ReviewMenuTemplate({ data, location }) {
	const {
		allDispensariesJson: { edges: dispensaryData },
	} = data
	const { dispensary } = dispensaryData[0].node
	const { averageRating, city, id, name, reviews, state, totalReviews, zip } = dispensary
	const pageTitle = `Marijuana Product Menu for ${name} - ${city}, ${state}, ${zip}`
	return (
		<GlobalLayout pageTitle={pageTitle} location={location}>
			<DispensarySchema dispensary={dispensary} />
			<Flex flexDirection="column">
				<Box>
					<Flex alignItems="center" flexDirection="row" flexWrap="wrap">
						<Box>
							<ReviewPageHeader>{pageTitle}</ReviewPageHeader>
						</Box>
						<Box>
							<ReviewsSummary
								dispensaryId={dispensary.id}
								averageRating={averageRating}
								totalReviews={totalReviews}
							/>
						</Box>
					</Flex>
				</Box>
				<Box>
					<ReviewsListWrapper>
						<ThemeProvider theme={fullTheme}>
							<ReviewsList
								isShowingAllReviews
								numPreview={10}
								reviewNodeId={id}
								reviewNodeType={NODE_TYPES.DISPENSARIES}
								reviews={reviews}
							/>
						</ThemeProvider>
					</ReviewsListWrapper>
				</Box>
			</Flex>
		</GlobalLayout>
	)
}

ReviewMenuTemplate.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
}
export default ReviewMenuTemplate

export const pageQuery = graphql`
	query dispensaryReviews($id: String!) {
		allDispensariesJson(filter: { dispensary: { id: { eq: $id } } }) {
			edges {
				node {
					dispensary {
						address1
						address2
						averageRating
						city
						geoLocation {
							_latitude
							_longitude
						}
						hours
						id
						imageUrl
						name
						phone
						reviews {
							id
							dateCreated
							defaultAvatarThemeIndex
							nodeName
							profileImageURL
							prefersUsername
							username
							firstName
							lastName
							rating
							reviewText
							reviewTitle
							reviewType
							reviewerName
							slug
							state
							userID
						}
						slug
						state
						totalReviews
						zip
					}
				}
			}
		}
	}
`
