import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { COLORS, FONT_SIZES } from 'utils/styleHelpers'
import { StarInput, StarList, StarListItem, Star } from './styles'
import { Star as StarIcon } from 'components/Iconography'
/**
 *	Thanks https://github.com/voronianski/react-star-rating-component
 * */

function StarRating({
	emptyStarColor = COLORS.MEDIUM_LIGHT_GRAY,
	isEditing = true,
	name,
	onStarClick,
	onStarHover,
	onStarHoverOut,
	onChange,
	renderStarIcon = StarIcon({ fontSize: FONT_SIZES.H5 }),
	renderStarIconHalf,
	starRatingId,
	starColor = COLORS.YELLOW,
	starPadding = null,
	starCount = 5,
	value = 0,
}) {
	const [rating, setRating] = useState(value)
	useEffect(() => {
		setRating(value)
	}, [value])

	const onStarClickHandler = (index, value, name, e) => {
		e.stopPropagation()

		if (!isEditing) {
			return true
		}

		if (onStarClick && onChange) {
			onStarClick(index, value, name, e)
			onChange(index, value, name, e) // connects to final form
		}

		return true
	}

	const onHoverHandler = (index, value, name, e) => {
		e.stopPropagation()

		if (!isEditing) {
			return true
		}
		if (onStarHover) {
			onStarHover(index, value, name, e)
		}
		return true
	}

	const onStarHoverOutHandler = (index, value, name, e) => {
		if (!isEditing) {
			return true
		}

		if (onStarHoverOut) {
			onStarHoverOut(index, value, name, e)
		}
		return true
	}

	function renderIcon(index, value, name, id) {
		if (typeof renderStarIconHalf === 'function' && Math.ceil(value) === index && value % 1 !== 0) {
			return renderStarIconHalf(index, value, name, id)
		}

		if (typeof renderStarIcon === 'function') {
			return renderStarIcon(index, value, name, id)
		}

		return (
			<i key={`icon_${id}`} style={{ fontStyle: 'normal' }}>
				&#9733;
			</i>
		)
	}

	function renderStars() {
		// populate stars
		const starNodes = []

		for (let i = starCount; i > 0; i--) {
			const id = `${starRatingId}_star${i}`
			const isAwarded = rating >= i
			const StarElement = (
				<StarListItem key={`label_${id}`} role="listbox">
					<StarInput
						key={`input_${id}`}
						defaultChecked={rating === i}
						id={id}
						name={name}
						onChange={inputValue => {
							if (!isEditing) {
								return
							}

							// do not update internal state based on input value if prop passed
							if (value != null) {
								return
							}
							setRating(inputValue)
						}}
						type="radio"
						value={i}
						enabled={isEditing}
					/>
					<Star
						aria-labelledby={id}
						aria-selected="false"
						htmlFor="rate"
						onClick={e => {
							onStarClickHandler(i, rating, name, e)
						}}
						onFocus={e => {
							onHoverHandler(i, rating, name, e)
						}}
						onKeyDown={e => {
							onStarClickHandler(i, rating, name, e)
						}}
						onMouseLeave={e => {
							onStarHoverOutHandler(i, rating, name, e)
						}}
						onMouseOver={e => {
							onHoverHandler(i, rating, name, e)
						}}
						role="option"
						isAwarded={isAwarded}
						isEditing={isEditing}
						starColor={starColor}
						emptyStarColor={emptyStarColor}
						tabIndex={0}
						starPadding={starPadding}
					>
						{renderIcon(i, rating, name, id)}
					</Star>
				</StarListItem>
			)
			starNodes.push(StarElement)
		}

		return starNodes.length ? starNodes : null
	}
	return <StarList>{renderStars()}</StarList>
}

StarRating.propTypes = {
	emptyStarColor: PropTypes.string,
	isEditing: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	onStarClick: PropTypes.func,
	onStarHover: PropTypes.func,
	onStarHoverOut: PropTypes.func,
	renderStarIcon: PropTypes.func,
	renderStarIconHalf: PropTypes.func,
	starRatingId: PropTypes.string.isRequired,
	starColor: PropTypes.string,
	starCount: PropTypes.number,
	starPadding: PropTypes.string,
	value: PropTypes.number,
}
export default StarRating
